import React from 'react';

const Footer = () => {

  return (
    <div className="page-footer row">
      <div className="col-4">&copy; 2024 Terrace Metrics, Inc. </div>
      <div className="col-8 text-right">For assistance email us at info@terracemetrics.org or contact us at 800-470-4401.</div>
    </div>
  );
}

export default Footer;
