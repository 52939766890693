import React, { Component } from 'react';
import { getContent } from '../../../../utilities/getContent/getContent';
import GroupHeader from './Header';

class DistrictIntroPage extends Component {
  render() {
    const payload = this.props.payload;
    const questionList = payload.surveyIndicators[0].questions;
    const questionSocial = questionList.filter(item => item.questionCategory === 'Social Adaptability Concerns');
    const questionAcademic = questionList.filter(item => item.questionCategory === 'Academic Concerns');
    const questionAggression = questionList.filter(item => item.questionCategory === 'Verbal/Social Aggression');
    const tableRowsquestionSocial = questionSocial.map((item, index) => (
        <tr key={index}>
          <td>{item.questionTopic}</td>
          <td>{getContent(item.questionText)}</td>
        </tr>
    ));
    const tableRowsquestionAcademic = questionAcademic.map((item, index) => (
      <tr key={index}>
        <td>{item.questionTopic}</td>
        <td>{getContent(item.questionText)}</td>
      </tr>
    ));
  const tableRowsquestionAggression = questionAggression.map((item, index) => (
    <tr key={index}>
      <td>{item.questionTopic}</td>
      <td>{getContent(item.questionText)}</td>
    </tr>
    ));
    return (
      <div className="page group-report" style={{marginTop:'100px'}}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5">
            <div className="row">
              <h1 className="text-uppercase">Introduction</h1>
              <p>
              Dear Parent, 
              Thank you for allowing your child to complete the resiliency/wellness assessment this Spring.  You were also invited to respond to 17 school-related behaviors that you may have observed in your child since the current school year began.  You rated each behavior on the following: Never, Rarely (observed between 1% - 15% of the time), Sometimes (from 16% - 45% of the time), Often (from 46% - 75% of the time) and Almost Always (greater than 76% of the time).   
              <br/>
              Table 1 describes each behavior you rated on your child.  These behaviors are clustered into three categories:
              </p>
            </div>
            <div className="d-flex flex-row">
              <table className="table table-sm table-bordered results mb-4">
                <thead>
                  <tr>
                    <th>Social Adaptability Concerns</th>
                    <th className="text-center">Actual Question</th>
                  </tr>
                </thead>
                <tbody>
                  {tableRowsquestionSocial}
                </tbody>
              </table>
            </div>
            <div className="d-flex flex-row">
              <table className="table table-sm table-bordered results mb-4">
                <thead>
                  <tr>
                    <th>Academic Concerns</th>
                    <th className="text-center">Actual Question</th>
                  </tr>
                </thead>
                <tbody>
                  {tableRowsquestionAcademic}
                </tbody>
              </table>
            </div>
            <div className="d-flex flex-row">
              <table className="table table-sm table-bordered results mb-4">
                <thead>
                  <tr>
                    <th>Verbal/Social Aggression</th>
                    <th className="text-center">Actual Question</th>
                  </tr>
                </thead>
                <tbody>
                  {tableRowsquestionAggression}
                </tbody>
              </table>
            </div>
            <div className="d-flex flex-row mt-5">
              <h2>Computing an Overall Score</h2>
            </div>
            <div className="d-flex flex-row justify-content-around">
              <div>
                <p>
                  Your rating on all items were summed to provide an overall score. 
                  This score estimates the degree of negative influence that these observed behaviors may have on academic functioning and Resiliency/Wellness. 
                  Scores range from 1 to 68, with higher scores indicating difficulties across a number of behaviors.
                  <br/><br/>
                  Although parent-reported behaviors should never be used as the sole means to gauge a child's academic and behavioral status, 
                  they can be used along with other to better understand how key behaviors may be impacting their learning progress and overall Resiliency/Wellness.   
                  <br/><br/>
                  The following pages report your child's Overall Score, and how you endorsed each item.
                  Specific recommendations are also provided, which can be shared with school personnel or other professionals.
                </p>
              </div>
              <table className="table-tm-dark-striped">
                <thead>
                  <tr>
                    <th>Scoring Range</th>
                    <th className="text-center">Category</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1-14</td>
                    <td>Mild: Behaviors endorsed as rarely occurring, or a few behaviors that are observed occasionally. Can be likely addressed through simple modifications at home and/or school.</td>
                  </tr>
                  <tr>
                    <td>15-29</td>
                    <td>Moderate: Multiple behaviors are endorsed, with some behaviors observed over half the time.  May require more focused classroom and/or individual modification strategies</td>
                  </tr>
                  <tr>
                    <td>30-44</td>
                    <td>Of High Concern: Multiple behaviors endorsed, with many observed more than half the time. Likely requires concerted classroom and individualized strategies.</td>
                  </tr>
                  <tr>
                    <td>45-68</td>
                    <td>Severe:  Multiple behaviors are endorsed and observed with very high frequency.  Will require concerted individualized strategies.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default DistrictIntroPage;
