import React from 'react';
import dayjs from 'dayjs';
import { Button } from 'reactstrap';
import { withRouter, useHistory } from 'react-router-dom';
import { dateFormat } from '../../../../utilities/dates';
import MOCK_INDICATORS from '../mock-indicators';
import { getContent } from '../../../../utilities/getContent/getContent';
import KidsSmiling from '../../../../assets/imgs/report-kids-smiling.jpg';
import AdultsChilling from '../../../../assets/imgs/middle-aged-folk-hanging-out-1254x268.jpg'

const ParentIntroPage = ({
  showSpanishLink,
  payload,
  showReturnButton
}) => {
  const history = useHistory();
  // previously using payload.indicators which was missing bullying
  const payloadIndicators = payload.surveyIndicators;
  let mockIndicators;
  mockIndicators = MOCK_INDICATORS.map(mockIndicator => {
    const indicators = mockIndicator.indicators.filter(indicator => {
      return payloadIndicators.some(payloadIndicator => {
        return payloadIndicator.indicator === indicator.label;
      });
    });
    const contentMappedIndicators = indicators.map(indicator => {
      return {
        label: getContent(indicator.contentId),
        description: getContent(indicator.description)
      }
    })

    if (contentMappedIndicators.length > 0) {
      return {
        label: getContent(mockIndicator.contentId || mockIndicator.label),
        description: getContent(mockIndicator.description),
        indicators: contentMappedIndicators
      };
    }
  });
  mockIndicators = mockIndicators.filter(newIndicator => newIndicator !== undefined);
  const date = dayjs().format(dateFormat);

  const isAdult = payload.appliedTo && payload.appliedTo === 'adult';
  const isUK = process.env.STAGE === 'uk';
  return (
    <div className="page page-parent-report parent-report-intro container">
      {typeof showReturnButton === 'boolean' && showReturnButton === true ? (
        <Button
          type="submit"
          className="small-blue-button-outlined mb-3"
          size="lg"
          variant="success"
          onClick={() => history.push(`/wellness-center`)}
        >
          Return to Wellness {isUK ? 'Centre' : 'Center'}
        </Button>
      ) : null}
      <div className="intro-hero mb-5 ">
        <div className="d-flex row align-items-center mb-md-4">
          <div className="col-12 col-md-6 mb-4 mb-md-0">
            <h1 className="h1 mb-2">{getContent('report_heading-resiliency-survey')}</h1>
            <h3 className="mb-2">{getContent('report_subhead-summary-of-results')}</h3>
            <p>{getContent('global_copyright')}
              {typeof showSpanishLink === 'boolean' && showSpanishLink === true ? (
                <span style={{ float: "right" }}>Click <a href={`/admin/reports/pdf/parent?pId=${payload.surveyParticipantId}&lang=es-US`}>HERE </a>for Español </span>
              ) : null}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <div className="bg-light p-3">
              <h2 className="mb-2 font-size-sm">
                <strong>{payload.participant.firstName} {payload.participant.lastName}</strong>{/* payload.participant.studentId ? ` (#${payload.participant.studentId})` : null */}
              </h2>
              <p className="mb-2 font-size-sm">
                <strong>
                  {!isAdult ? getContent('report_grade') : null} {!isAdult ? payload.participant.grade : null} {!isAdult ? '-' : null} {payload.participant.schoolName}
                </strong>
                <br />
                {payload.orgDistrict}
                <br />
                {date}
              </p>
            </div>
          </div>
        </div>
        <img className="image-fit" src={!isAdult ? KidsSmiling : AdultsChilling} width="100%" alt="resiliency" />
      </div>
      <div className="purpose">
        <p className="mb-2">
          {isAdult ? null : getContent('report_content-dear-parent')}<br />
          {isAdult ? null : payload.participant.firstName} {isAdult ? 'You' : payload.participant.lastName}{getContent(isAdult ? getContent('report_content-intro-1-adult') : 'report_content-intro-1')}
        </p>
        <p className="mb-4">{isAdult ? getContent('report_content-intro-2-adult') : getContent('report_content-intro-2')}</p>
      </div>
      <div className="purpose">
        <h2 className="mb-2">{isAdult ? getContent('report_heading-purpose-of-assessment-adult') : getContent('report_heading-purpose-of-assessment')}</h2>
        <p className="mb-4">{isAdult ? getContent('report_content-purpose-of-assessment-adult') : getContent('report_content-purpose-of-assessment')}</p>
      </div>
      <div className="what-it-measures">
        <h2 className="mb-2">{getContent('report_heading-what-assessment-measures')}</h2>
        <p className="mb-4">{getContent('report_content-what-assessment-measures')}</p>
      </div>
      <div className="row d-print-block clearfix">
        {mockIndicators.map((core, index) => {
          return (
            <div className="core-indicator col-12 col-md-6 col-print-6" key={index}>
              <div className="p-2">
                <h2 className="mb-2">{core.label}</h2>
                <p className="mb-2">{core.description}</p>
                <table className="table table-sm  table-striped font-size-xs">
                  <tbody>
                    {core.indicators.map((indicator, ii) => {
                      return (
                        <tr key={ii}>
                          <td width="30%">
                            <strong>{indicator.label}</strong>
                          </td>
                          <td>{indicator.description}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
      <p className="mt-1 clearfix">
        {getContent('report_content-results-instructions-1')} {isAdult ? null : payload.participant.firstName} {isAdult ? 'your' : payload.participant.lastName}{isAdult ? getContent('report_content-results-instructions-2-adult') : getContent('report_content-results-instructions-2')}
      </p>
    </div>
  );
}

export default withRouter(ParentIntroPage);
