import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import ParentSuggestionPage from './SuggestionsPage';
import TFSReport from '../Shared/TFSReport';
import ParticipantHeader from '../Shared/ParticipantHeader';
import ParentIntroPage from './IntroPage';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import Spinner from '../../../../components/Spinner/Spinner';

import * as actions from '../../../../store/actions/';

const ParentReport = ({
  location,
  setLang,
  getReport,
  isLoading,
  report,
  showReturnButton
}) => {
  const parsed = queryString.parse(location.search);
  const participantId = parsed.pId;
  const showSpanishLink = parsed.showSpanishLink && parsed.showSpanishLink === "true";

  useEffect(() => {
    if (parsed.lang) {
      setLang(parsed.lang);
    }
    getReport(participantId);
  }, [location.search])

  return (
    <div className="tm-report tm-report-student tm-report-parent">
      {isLoading === false && report ? (
        <>
          {/* Header is sticky so it prints on each page */}
          <ParticipantHeader isParentReport={true} payload={report} showLocalization={true} showPrint={true} />
          {/**  Start Parent  **/}
          <ParentIntroPage payload={report} showReturnButton={showReturnButton} showSpanishLink={showSpanishLink} />
          <TFSReport payload={report} showTimeBox={false} isParentReport={true} />
          <ParentSuggestionPage payload={report} />
        </>
      ) : (
        <Backdrop>
          <Spinner />
        </Backdrop>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    report: state.reports.report,
    isLoading: state.reports.isLoading,
    lang: state.meta.lang
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReport: participantId => dispatch(actions.getReport(participantId)),
    setLang: (newLang) => dispatch(actions.setLang(newLang)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ParentReport));
