import React from 'react';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

const roleHr = {
  district_champion: 'Organization Champion',
  school_champion: 'Group Champion',
  tm_admin: 'TM Admin',
};

// This modal is for editing all possible survey attributes
// But for now it's only for DRs (Designated Recipients)
const DesignatedRecipientPicker = props => {
  const selectOptions = props.champions.map(champion => {
    const championRoleHr = roleHr[champion.role] || null;
    return {
      label: `${champion.firstName} ${champion.lastName} (${championRoleHr})`,
      value: champion.email,
    };
  });
  props.designatedRecipient &&
    props.designatedRecipient.forEach(emailAddress => {
      if (!props.champions.some(c => c.email === emailAddress)) {
        // designated recipient is not in the champions list
        selectOptions.push({ label: emailAddress, value: emailAddress });
      }
    });
  const selectValue = selectOptions.filter(option =>
    props.designatedRecipient.includes(option.value),
  );
  const changeHandler = selectedOptions => {
    const values = selectedOptions.map(option => option.value);
    console.log(values);
    props.setDesignatedEmailRecipient(values);
  };
  // const saveNewDRs = async () => {
  //   setSaving(true);
  //   console.log(`setSaving true`);
  //   const url = `${process.env.REACT_APP_API_URL}/surveys`;
  //   const response = await axios({
  //     url,
  //     method: 'POST',
  //     data: {
  //       surveys: [
  //         {
  //           id: props.surveyId,
  //           designatedRecipient: DRs,
  //         },
  //       ],
  //     },
  //     headers: { Authorization: idToken },
  //   });
  //   if (response.status === 200) {
  //     props.refreshData(props.surveyId);
  //     props.closeModal()
  //   }
  //   setSaving(false);
  //   console.log(`setSaving false`);
  // };

  return (
    <div className="">
      <CreatableSelect
        isMulti
        name="designatedRecipientSelect"
        value={selectValue}
        // defaultValue={selectDefaultValue}
        isClearable
        isLoading={!props.championsLoaded}
        options={selectOptions}
        formatCreateLabel={v => `Add '${v}'`}
        onChange={changeHandler}
        placeholder={"Select or Enter email address"}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    designatedRecipient: state.surveyCrud.createSurveyObj.designatedRecipient,
    champions: state.users.users,
    championsLoaded: state.users.isLoaded,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setDesignatedEmailRecipient: value =>
      dispatch(actions.setDesignatedEmailRecipient(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DesignatedRecipientPicker);
