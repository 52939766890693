import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import classnames from 'classnames';
import { DropdownToggle, DropdownMenu, DropdownItem, Input, Tooltip, UncontrolledDropdown, UncontrolledTooltip, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faDownload, faFileArrowDown, faGear } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { withRouter, Link, useLocation, useHistory } from 'react-router-dom';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import Spinner from '../../../../components/Spinner/Spinner';
// import SearchSurveysForm from './SearchSurveyForm/SearchSurveyForm';
import * as actions from '../../../../store/actions';
import CheckedImage from '../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../assets/imgs/unchecked.png';
import getSchoolAndDistrict from '../../../../utilities/getSchoolAndDistrict';

const ListSurvey = React.memo(props => {

  useEffect(() => {
    if (props.surveyNotificationData) {
      openFileDownloadModal();
    }
  }, [props.surveyNotificationData])

  const openFileDownloadModal = async () => {
    let content;
    if (props.surveyNotificationData === "loading") {
      content = (<p>Please wait, we're preparing your data <FontAwesomeIcon spin={true} icon={faGear} /></p>);
    } else if (props.surveyNotificationData.url) {
      content = <p>Your data is ready. Please click <a href={props.surveyNotificationData.url}>here</a> to download.</p>
    } else {
      content = (<p>Sorry, something went wrong... please contact us.</p>)
    }
    props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Download Assessment Raw Data',
      headlineIcon: faDownload,
      content: content,
      onExit: props.resetAllNotificationData
    })
  }
  
  const getOptionsList = key => {
    const counter = {};
    const r = [];
    props.surveys.forEach(survey => {
      const surveyValue = survey[key];
      const valueString = typeof surveyValue === 'object' ? JSON.stringify(surveyValue) : surveyValue;
      if (counter[valueString]) {
        counter[valueString].count += 1;
      } else {
        counter[valueString] = {
          value: surveyValue,
          count: 1,
        };
      }
    });
    for (const value in counter) {
      const { value: parsedValue, count } = counter[value];
      r.push({
        value: parsedValue,
        label: typeof parsedValue === 'object' ? `${parsedValue.value} (${count})` : `${parsedValue} (${count})`,
      });
    }
    return r;
  };

  const history = useHistory();

  const [state, setState] = useState({
    selectedSurveys: [],
    filterIncludeArchived: false,
    filterYear: [],
    filterSemester: [],
    filterSchool: [props.schoolSearchName],
    filterStatus: [],
  });

  // const [showSpinner, setShowSpinner] = useState(true);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShowSpinner(false);
  //   }, 10000); // 10 seconds timeout

  //   return () => clearTimeout(timeout);
  // }, [props.schoolSearchName]);

  // const { searchAssessment } = props.match.params;
  // const [filterName, setFilterName] = useState(searchAssessment || '');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    const values = getSchoolAndDistrict(props.org);
    if (props.surveysListParam && props.surveysListParam.schoolId === values.schoolId && props.surveysListParam.districtId === values.districtId) return;
    props.listSurveys(values);
  }, [props.org, props.surveysListParam]);

  // const searchSubmissionHandler = () => {
  //   const values = {
  //     country: props.surveySearchCountryRegion,
  //     state: props.surveySearchStateProvince,
  //     district: props.surveySearchDistrict && props.surveySearchDistrict.orgId ? props.surveySearchDistrict.orgId : '',
  //     school: props.surveySearchSchool.id,
  //     year: props.surveySearchYear,
  //     name: props.surveySearchName,
  //   };
  //   props.listSurveys(values);
  // };

  const redirectToSurveyDetails = id => {
    props.history.push({
      pathname: `/admin/survey/details/${id}`,
    });
  };

  const handleClick = e => {
    const surveyId = e.target.getAttribute('data-id');
    const updatedSelectedSurveys = [ ...state.selectedSurveys ];
    const index = updatedSelectedSurveys.indexOf(surveyId);
    if (index !== -1) {
      updatedSelectedSurveys.splice(index, 1); 
    } else {
      updatedSelectedSurveys.push(surveyId);
    }
    setState(prevState => ({
      ...prevState,
      selectedSurveys: updatedSelectedSurveys,
    }));
  };
  const handleArchiveSurveysButton = () => {
    props.archiveSurveys(state.selectedSurveys, () => { props.listSurveys(getSchoolAndDistrict(props.org)) });
  };
  const location = useLocation();

  const { innerWidth: width } = window;

  const isMobileScreen = width < 800;

  const updateQueryParams = (key, value) => {
    const searchParams = new URLSearchParams('?' + location.hash.slice(1));
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    // console.log(searchParams.toString());
    history.push({
      // pathname: location.pathname,
      hash: '#' + searchParams.toString(),
    });
  };

  const handleChangeFilter = (filterKey, items) => {
    const filterValues = items ? items.map(item => item.value) : [];
    // console.log(`filterKey : ${filterKey}`);
    setState(prevState => ({
      ...prevState,
      [`filter${filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}`]: filterValues,
    }));
    updateQueryParams(filterKey, filterValues.join(','));
  };

  const handleChangeArchieved = () => {
    const newFilterIncludeArchived = !state.filterIncludeArchived;
    setState(prevState => ({
      ...prevState,
      filterIncludeArchived: newFilterIncludeArchived,
    }));
    updateQueryParams('archived', newFilterIncludeArchived);
  };

  // const [prevYears, setPrevYears] = useState([])

  const updateStateFromQueryParams = params => {
    const group = params.get('group') ? params.get('group').split(',') : [];
    const status = params.get('status') ? params.get('status').split(',') : [];
    const year = params.get('year') ? params.get('year').split(',') : [];
    const semester = params.get('semester') ? params.get('semester').split(',') : [];
    const archived = params.get('archived') === 'true';
    // console.log({ group, status, year, semester, archived });
    setState(prevState => ({
      ...prevState,
      filterSchool: group,
      filterYear: year,
      filterSemester: semester,
      filterStatus: status,
      filterIncludeArchived: archived,
    }));
  };

  useEffect(() => {
    const s = '?' + location.hash.slice(1);
    const params = new URLSearchParams(s);
    updateStateFromQueryParams(params);
  }, []);

  const filterSurvey = survey => {
    let isMatching = true;
    if (
      (survey.hasOwnProperty('isArchived') && survey.isArchived && !state.filterIncludeArchived) ||
      (!survey.isArchived && state.filterIncludeArchived) ||
      (survey.hasOwnProperty('year') && state.filterYear.length > 0 && !state.filterYear.includes(survey.year.toString())) ||
      (survey.hasOwnProperty('semester') && state.filterSemester.length > 0 && !state.filterSemester?.includes(survey.semester)) ||
      (survey.hasOwnProperty('schoolName') && state.filterSchool.length > 0 && !state.filterSchool?.includes(survey.schoolName)) ||
      (props.schoolSearchName && !props.schoolSearchName?.includes(survey.orgId)) ||
      (props.districtSearchName && !props.districtSearchName?.includes(survey.parentOrgId)) ||
      // (filterName !== '' && !new RegExp(filterName, 'i').test(survey.surveyName)) ||
      (survey.hasOwnProperty('surveyStatus') && state.filterStatus.length > 0 && !state.filterStatus?.includes(survey.surveyStatus))
    )
      isMatching = false;
    return isMatching;
  };

  const generateTableData = () => {
    if (props.surveys && props.surveys.length > 0) {
      return props.surveys.filter(filterSurvey).map(item => {
        const obj = {};
        {
          obj.selected = <img className="checkbox" style={{ cursor: 'pointer' }} alt="Checkbox" src={state.selectedSurveys.includes(item.surveyId) ? CheckedImage : UncheckedImage} onClick={handleClick} data-id={item.surveyId} />;
        }
        if (props.role && props.role === 'tm_admin' && !isMobileScreen) {
          obj.schoolName =
            // <button className="button-text" onClick={() => changeRoleAndOrg(item)}>
            item.schoolName ||
            // </button>
            '';
        }
        // if and only if you are district champion you will see a school name with plain text instead of the context switch link
        if (props.role === 'district_champion' && !isMobileScreen) {
          obj.schoolName = item.schoolName;
        }
        {
          obj.surveyName = (
            <button onClick={() => redirectToSurveyDetails(item.surveyId)} className="button-text">
              {item.surveyName}
            </button>
          );
        }
        if (!isMobileScreen) {
          obj.date = new Date(item.createdAt).toLocaleDateString();
        }
        obj.surveyStatus = item.surveyStatus;
        obj.surveyStatus == 'Completed'
          ? (obj.result = (
              <Link
                className="button-text"
                to={{
                  pathname: `/admin/reports/survey?id=${item.surveyId}`,
                }}
                target="_blank"
              >
                <FontAwesomeIcon icon={faFileLines}></FontAwesomeIcon>
              </Link>
            ))
          : (obj.result = '-');
        return obj;
      });
    }
    return [];
  };

  const isUK = () => process.env.REACT_APP_STAGE === 'uk';

  const assessmentnNameHeader = (
    <div className="d-flex">
      <div>ASSESSMENT NAME </div>
      <div style={{ marginLeft: '2px' }} id="info-assessmentname">
        <FontAwesomeIcon icon={faCircleInfo} />
      </div>
      <UncontrolledTooltip placement={'top'} target={'info-assessmentname'}>
        Click on the name of assessment to view progress and manage
      </UncontrolledTooltip>
    </div>
  );

  const assessmentStatusHeader = (
    <div className="d-flex">
      <div>ASSESSMENT STATUS </div>
      <div style={{ marginLeft: '2px' }} id="info-assessmentstatus">
        <FontAwesomeIcon icon={faCircleInfo} />
      </div>
      <UncontrolledTooltip placement={'right'} target={'info-assessmentstatus'}>
        See status of assessment and access report (when all participants have completed)
      </UncontrolledTooltip>
    </div>
  );

  let headers = [!isMobileScreen ? `${isUK() ? 'ORGANISATION NAME' : 'GROUP NAME'}` : undefined, assessmentnNameHeader, !isMobileScreen && 'ASSESSMENT DATE', assessmentStatusHeader, 'REPORT'];

  if (props.role && props.role === 'district_champion') {
    headers = [
      !isMobileScreen ? `${isUK() ? 'ORGANISATION NAME' : 'GROUP NAME'}` : undefined,
      assessmentnNameHeader,
      !isMobileScreen ? 'ASSESSMENT DATE' : undefined,
      assessmentStatusHeader,
      'REPORT',
    ];
  }

  if (props.role && props.role === 'school_champion') {
    headers = [assessmentnNameHeader, !isMobileScreen && 'ASSESSMENT DATE', assessmentStatusHeader, 'REPORT'];
  }

  headers.unshift(props.isSurveySearchVisible ? 'SELECT' : 'SELECT');

  const tableContentRows = generateTableData();
  const filterSurveysCount = tableContentRows.length;
  const selectedSurveysCount = state.selectedSurveys.length;

  // const surveyDetails = props.surveys.map(surveys => ({
  //   label: surveys.surveyName,
  //   value: surveys.surveyName,
  // }));

  // const handleFilterName = async event => {
  //   console.log(event);
  //   let resentSearch;
  //   if (event) {
  //     setFilterName(event.value);
  //     resentSearch = event.value;
  //   } else {
  //     setFilterName('');
  //     resentSearch = '';
  //   }
  //   props.history.push(`/admin/survey/list/all/${resentSearch}`);
  // };

  const getGroupOptionsFromState = () => {
    return state.filterSchool.map(value => ({ value, label: value }));
  };

  const getYearOptionsFromState = () => {
    return state.filterYear.map(value => ({ value: parseInt(value), label: value }));
  };

  const getSemesterOptionsFromState = () => {
    return state.filterSemester.map(value => ({ value, label: value }));
  };

  const getStatusOptionsFromState = () => {
    return state.filterStatus.map(value => ({ value, label: value }));
  };

  const handleBulkDownload = () => {
    props.getAllNotificationData(state.selectedSurveys);
  };

  return (
    <div className="survey-list-container">
      {/* <div className={classnames("container-fluid", props.isSurveySearchVisible ? null : 'hidden')}>
        <div className="row">
          <div className="col-12">
            <SearchSurveysForm onSubmit={searchSubmissionHandler} />
          </div>
        </div>
      </div> */}
      <div className="container-fluid">
        <div className="row container-fluid__filters">
          <Col lg="9" className="border rounded border-secondary  pt-2 pb-2">
            <h2>Filter Assessments</h2>
            <div className="flex-row">
              {props.realRole && ['district_champion', 'tm_admin'].includes(props.realRole) ? (
                <div className="container-fluid__filter__flex">
                  <div className="label-field-container">
                    <label className="label" htmlFor="filterSchool">
                      Group
                    </label>
                    <Select
                      isMulti
                      isClearable
                      classNamePrefix="select"
                      options={getOptionsList('schoolName')}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 2 }) }}
                      value={getGroupOptionsFromState()}
                      onChange={items => handleChangeFilter('group', items)}
                    />
                  </div>
                </div>
              ) : null}
              <div className="label-field-container">
                <label className="label" htmlFor="filterYear">
                  Year
                </label>
                <Select isMulti isClearable classNamePrefix="select" value={getYearOptionsFromState()} options={getOptionsList('year')} onChange={items => handleChangeFilter('year', items)} />
              </div>
              <div className="label-field-container">
                <label className="label" htmlFor="filterSemester">
                  Semester
                </label>
                <Select
                  isMulti
                  isClearable
                  isSearchable
                  options={getOptionsList('semester')}
                  classNamePrefix="select"
                  placeholder="Select Semester"
                  value={getSemesterOptionsFromState()}
                  onChange={items => handleChangeFilter('semester', items)}
                />
              </div>
              {/* <div className="label-field-container">
                <label className="label" htmlFor="filterSurveyName">
                  Survey Name
                </label>
                <Select
                  classNamePrefix="select"
                  isSearchable
                  name="filterSurveyName"
                  options={surveyDetails}
                  placeholder={'Survey name'}
                  onChange={handleFilterName}
                  value={filterName ? { value: filterName, label: filterName } : null}
                  isClearable
                />
              </div> */}
              <div>
                <label className="label" htmlFor="filterStatus">
                  Status
                </label>
                <Select
                  isMulti
                  isClearable
                  classNamePrefix="select"
                  options={getOptionsList('surveyStatus')}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 2 }) }}
                  value={getStatusOptionsFromState()}
                  onChange={items => handleChangeFilter('status', items)}
                />
              </div>
              <div className="label-field-container">
                <div className="d-flex">
                  <div className="search-survey-header">Archived</div>
                  <FontAwesomeIcon icon={faCircleInfo} color="#3c7dc2" id={'info-archived'} />
                </div>
                <UncontrolledTooltip placement={'top'} target={'info-archived'}>
                  Click to see archived assessments
                </UncontrolledTooltip>
                <label htmlFor="filterIncudeArchived">
                  <Input type="checkbox" onChange={() => handleChangeArchieved('archieved')} checked={state.filterIncludeArchived} />
                </label>
              </div>
            </div>
            <div>{`Showing ${filterSurveysCount} out of ${props.surveys.length} assessments.`}</div>
          </Col>
          <Col lg="3" className="border rounded border-secondary  pt-2 pb-2">
            <h2>
              Bulk Actions <FontAwesomeIcon icon={faCircleInfo} color="#3c7dc2" id={'info-action'} />
            </h2>
            <Tooltip placement={'top'} isOpen={tooltipOpen} target={'info-action'} toggle={toggle}>
              Perform actions on multiple assessments by selecting them from the list
            </Tooltip>
            <div className="search-survey-action">
              <UncontrolledDropdown direction="down" className="search-survey__dropdown">
                <DropdownToggle disabled={selectedSurveysCount === 0} caret color="primary">
                  Actions
                </DropdownToggle>
                <DropdownMenu >
                  <DropdownItem id="locator_archive-survey-button" onClick={handleArchiveSurveysButton}>
                    <FontAwesomeIcon icon={faFileArrowDown} className="search-survey-action__button" />
                    Archive
                  </DropdownItem>
                  <DropdownItem  onClick={handleBulkDownload}>
                    <FontAwesomeIcon icon={faDownload} className="search-survey-action__button" />
                    Download Raw Data
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
        </div>
      </div>
      <div>
        {props.isLoading ? (
          <div>
            Loading Assessments...
            <Spinner />
          </div>
        ) : (
          <>
            {props.surveys.length > 0 ? (
              <TableGenerator
                config={{
                  headers: headers,
                  contentRows: tableContentRows,
                }}
              />
            ) : (
              <p>No results found</p>
            )}
          </>
        )}
      </div>
    </div>
  );
});
ListSurvey.displayName = 'ListSurvey';

const mapStateToProps = state => {
  return {
    // isRosterUploading: state.surveyCrud.isRosterUploading
    // isSurveySearchVisible: state.surveyCrud.isSurveySearchVisible,
    surveys: state.surveyCrud.surveys,
    surveysListParam: state.surveyCrud.surveysListParam,
    org: state.navigation.org,
    isLoading: state.surveyCrud.isLoading,
    role: state.auth.role,
    districtSearchName: state.organizations.districtSearchName,
    schoolSearchName: state.organizations.schoolSearchName,
    surveyNotificationData: state.surveyCrud.surveyNotificationData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listSurveys: values => dispatch(actions.listSurveys(values)),
    showSurveySearch: () => dispatch(actions.showSurveySearch()),
    submitSurveySearch: () => dispatch(actions.submitSurveySearchStart()),
    changeRole: role => dispatch(actions.changeRole(role)),
    changeOrganization: orgId => dispatch(actions.changeOrganization(orgId)),
    hideSurveySearch: () => dispatch(actions.hideSurveySearch()),
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => dispatch(actions.closeModal()),
    archiveSurveys: (surveyIds, cb) => dispatch(actions.archiveSurveys(surveyIds, cb)),
    // downloadSurveys: surveyListIds => dispatch(actions.downloadSurveys(surveyListIds)),
    getAllNotificationData: surveyIds => dispatch(actions.getAllNotificationData(surveyIds)),
    resetAllNotificationData: () => dispatch(actions.resetAllNotificationData()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListSurvey));
