import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getContent } from '../../../../../utilities/getContent/getContent';
import Select from 'react-select';

import * as actions from '../../../../../store/actions';
// import IndicatorSelectFormCategoryGroup from './IndicatorSelectFormCategoryGroup/IndicatorSelectFormCategoryGroup';
import IndicatorSelectFormTypeGroup from './IndicatorSelectFormCategoryGroup/IndicatorSelectFormTypeGroup';

const IndicatorSelectForm = props => {
  const isUK = () => process.env.REACT_APP_STAGE === 'uk';
  const appliedTo = props.surveyCrud.createSurveyObj.appliedTo;
  const [profession, setProfession] = useState(null);
  const setProfessionHandler = (event) => {
    setProfession(event); // the component state
    props.setProfession(event.value); // the redux state
  }


  const professionList = props.surveyCrud.professions.map(profession => ({
    label: getContent(profession.professionId),
    value: profession.professionId,
  }));

  // const chosenIndicators = props['surveyCrud']['createSurveyObj']['chosenIndicators'];

  // const copiedIndicators = props.surveyCrud.surveyDetails.indicators;
  // const allIndicators = props.surveyCrud.indicators;

  // let filteredIndicators = allIndicators.filter(indicator => copiedIndicators?.includes(indicator.indicator));
  // filteredIndicators.forEach((indicator, index) => {
  // let concatenatedIndicatorIds = '';
  // concatenatedIndicatorIds += indicator.indicatorId;
  // if (index < filteredIndicators.length - 1) {
  //   concatenatedIndicatorIds;
  // }

  // if (concatenatedIndicatorIds !== '' && !chosenIndicators.includes(concatenatedIndicatorIds)) {
  //   chosenIndicators.push(concatenatedIndicatorIds);
  // }
// });

  // useEffect(() => {
  //   getChosenIdicators()
  // },[copiedIndicators])


  return (
    <>
      <div className="content-indicator-select-form">
        {Object.keys(props.surveyCrud.indicatorsByCategoryAndType)
          .sort()
          .map(typeName => {
            return (
                <IndicatorSelectFormTypeGroup
                  key={typeName}
                  typeName={typeName}
                  indicatorsByCategoryAndType={props.surveyCrud.indicatorsByCategoryAndType}
                  indicatorIdsByCategory={props.surveyCrud.indicatorIdsByCategory}
                  chosenIndicators={props.surveyCrud.createSurveyObj.chosenIndicators}
                  toggleCategoryIndicators={props.toggleCategoryIndicators}
                  toggleCheckedIndicatorInForm={props.toggleCheckedIndicatorInForm}
                  appliedTo={props.surveyCrud.createSurveyObj.appliedTo}
                />
            );
          })}

        {/* {Object.keys(props.surveyCrud.indicatorsByCategoryAndType).map(categoryName => {
          return (
            <div key={categoryName}>
              {props.surveyCrud.isLoading === false && props.surveyCrud.indicatorsByCategoryAndType ? (
                <IndicatorSelectFormCategoryGroup
                  key={categoryName}
                  chosenIndicators={chosenIndicators}
                  categoryName={categoryName}
                  indicatorsByCategoryAndType={props.surveyCrud.indicatorsByCategoryAndType}
                  indicatorIdsByCategory={props.surveyCrud.indicatorIdsByCategory}
                  toggleCheckedIndicatorInForm={props.toggleCheckedIndicatorInForm}
                  chosenCategories={props.surveyCrud.createSurveyObj.chosenCategories}
                  toggleCategoryIndicators={props.toggleCategoryIndicators}
                  isSelfHarmDisabled={props.surveyCrud.isSelfHarmDisabled}
                />
              ) : null}
            </div>
          );
        })} */}
      </div>
      {appliedTo === 'adult' && isUK() === false ? (
        <div className="row mt-5">
          <div className="col-10 content-profession-select-form">
            <div className="dropdown-container">
              <h1 className="indicator-headers">Select profession</h1>
              {/* <DropdownList
                  className="tm-dropdown mr-0"
                  id="locator_select-profession"
                  data={props.surveyCrud.professions}
                  textField="professionLabel"
                  valueField="professionId"
                  onChange={props.setProfession}
                  value={props.surveyCrud.createSurveyObj.profession}
                  placeholder="Please select..."
                /> */}
              <Select options={professionList} className="tm-dropdown mr-0" onChange={setProfessionHandler} placeholder="Please select..." value={profession} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {
  return {
    surveyCrud: state.surveyCrud,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCheckedIndicatorInForm: indicatorId => dispatch(actions.toggleCheckedIndicatorInForm(indicatorId)),
    setAppliedTo: value => dispatch(actions.setAppliedTo(value)),
    toggleCategoryIndicators: category => dispatch(actions.toggleCategoryIndicators(category)),
    setProfession: profession => dispatch(actions.setProfession(profession)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorSelectForm);
