import React from 'react';
import { Link } from 'react-router-dom';
import { adjustDate, dateFormat } from '../../../utilities/dates';
import { ReactComponent as ReportIcon } from '../../../assets/imgs/icon-survey-report.svg';
import { ReactComponent as ReferredIcon } from '../../../assets/imgs/referred.svg';
import TableGeneratorAdvance from '../../../components/TableGenerator/TableGeneratorAdvance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';

const TableParticipants = ({ participants, enableSearchById, enableSearchByName }) => {
  const getReportIcon = (num, item) => {
    return (
      <div>
        <Link
          className="button-text report-link"
          to={{
            pathname: `/admin/reports/student?pId=${item.surveyParticipantId}`,
          }}
          target="_blank"
        >
          <span className="report-icon" data-num={num > 0 ? num : null} data-contacted={item.contacted}>
            <ReportIcon />
          </span>
        </Link>
        {item.referredTo ? <ReferredIcon /> : null}
      </div>
    );
  };

  const LinkToSurvey = ({ title, style, surveyId, inner }) => {
    return (
      <Link target="_blank" title={title} style={style} to={{ pathname: `/admin/survey/details/${surveyId}` }}>
        {inner}
      </Link>
    );
  };

  const LinkSearchStudentId = ({ title, style, studentId }) => {
    return (
      <Link title={title} style={style} to={{ pathname: `/admin/participant/search/id/${encodeURIComponent(studentId)}` }}>
        {studentId}
      </Link>
    );
  };

  const ParticipantNameAndBirthDate = ({ participant, searchable }) => {
    const participantSearchKeyEncoded = encodeURIComponent(`${participant.lastName}#${participant.firstName}#${participant.birthDate}`);
    return (
      <>
        {`${participant.lastName}, ${participant.firstName} ${participant.middleName || ''}`}
        <div className="grade-label">
          {adjustDate(participant.birthDate).format(dateFormat)}{' '}
          {searchable ? (
            <Link to={{ pathname: `/admin/participant/search/key/${participantSearchKeyEncoded}` }}>
              <FontAwesomeIcon id="search_participant_same_name_dob" icon={faSearch} />
              <UncontrolledTooltip placement="right" target="search_participant_same_name_dob">
                Search for any participant matching this exact first and last name, and date of birth.
              </UncontrolledTooltip>
            </Link>
          ) : null}
        </div>
      </>
    );
  };

  // const ContextMenu = ({ item }) => {
  //   return (
  //     <div className="dropdown">
  //       <div className="small-gray-button-outlined context-menu">...</div>
  //       <div className="dropdown-content">
  //         <div>
  //           <span onClick={() => alert("Edit participant overlay")}>
  //             <FontAwesomeIcon icon={faEdit} className="blue-fill-icon" /> View/Edit Participant
  //           </span>
  //           <span>
  //             <FontAwesomeIcon icon={faEdit} className="blue-fill-icon" /> <LinkToSurvey style={{ textDecoration: 'none' }} surveyId={item.surveyId} inner="Go to Survey" />
  //           </span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const tableData = participants.map(item => {
    const SurveySemesterYear = ({ survey }) => {
      const s = survey ? `${survey.semester} ${survey.year}` : '';
      return <>{s}</>;
    };

    return {
      participant: (
        <>
          <ParticipantNameAndBirthDate participant={item} searchable={enableSearchByName} />
        </>
      ),
      studentid: item.studentId ? enableSearchById ? <LinkSearchStudentId title="Search this student ID" studentId={item.studentId} /> : item.studentId : '-',
      grade: item.grade,
      group: item.school,
      survey: (
        <>
          <LinkToSurvey title="Go to survey details (new window)" surveyId={item.surveyId} inner={item.surveyName || item.survey.surveyName} />
          <div className="grade-label">
            <SurveySemesterYear survey={item.survey} />
          </div>
        </>
      ),
      status: (
        <div>
          {item.participantStatus}
          {item.participantStatus === 'Completed' ? (
            <div className='grade-label'>
              {new Date(item.updatedAt).toLocaleDateString('en-US')}
            </div>
          ) : null}
        </div>
      ),
      result: item.participantStatus !== 'Completed' ? <span>-</span> : getReportIcon(item.priority, item),
      surveyParticipant: item,
    };
  });

  return (
    <TableGeneratorAdvance
      config={{
        headers: [
          { title: 'participant',  isSorting: true },
          { title: 'student id' },
          { title: 'grade'},
          { title: 'group', isSorting: true },
          { title: 'survey' },
          { title: 'status', isSorting: true },
          { title: 'result', isSorting: true },
        ],
        contentRows: tableData,
        itemsPerPage: 100,
        sortableColumns: {
          status: {
            getValue: rowItem => { return { 'Completed': 1, 'Not Started': 2, 'Not Completed': 3}[rowItem.surveyParticipant.participantStatus] },
            compareFn: (a, b) => b-a ,
          },
          result: {
            getValue: rowItem => rowItem.surveyParticipant.priority || (rowItem.surveyParticipant.participantStatus === "Completed" ? 6 : 7),
            compareFn: (a,b) => b-a
          },
          participant: {
            getValue: rowItem => rowItem.surveyParticipant.lastName.toLowerCase() + ' ' + rowItem.surveyParticipant.firstName.toLowerCase(),
            compareFn: (a,b) => b.localeCompare(a)
          },
          group: {
            getValue: rowItem => rowItem.surveyParticipant.school.toLowerCase(),
            compareFn: (a,b) => b.localeCompare(a)
          }
        }
}}
    />
  );
};

export default TableParticipants;
