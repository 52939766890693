import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GroupHeader from './Header';
import { getContent } from '../../../../utilities/getContent/getContent';

class DistressScores extends Component {
  render() {
    const payload = this.props.payload;
    const isAdult = payload.appliedTo && (payload.appliedTo === 'adult');
    const isUK = process.env.REACT_APP_STAGE === 'uk';
    // const urlPath = isAdult ? "adults" : "educators";
    // const urlPassword = isAdult ? "TMadults" : "TMeducators";
    const { tmEducatorsLink, tmEducatorsLinkUK } = this.props.links;
    // const educatorLinkStatement = () => {
    //   return (
    //     <span>
    //       <strong><a href={isUK ? tmEducatorsLinkUK : tmEducatorsLink}>{isUK ? "mymynd.co.uk/" : "terracemetrics.org/"}{urlPath}</a></strong>, using the password <strong>"{urlPassword}"</strong>
    //     </span>
    //   )
    // }

    const getAggregatedDistressScores = ({ self }) => {
      const currentScores = self.props.payload.reports.distressScores || [];
      const relatedScores =
        (self.props.selectedRelatedReport && self.props.selectedRelatedReport.reports.distressScores)
        || [];

      const result = [];

      for (const score of currentScores)
        result.push({
          current: score,
          related: { ...score, survey: "No historical data" }
        });

      for (const score of relatedScores) {
        let maybeExisting = result.find(({ current }) => current.title === score.title);

        if (maybeExisting) {
          maybeExisting.related = score;
        } else {
          maybeExisting = {
            related: score,
            current: { ...score, survey: 'No current data' }
          };
          result.push(maybeExisting);
        }
      }

      return result;
    };

    const getAggregatedRiskVariables = ({ self }) => {
      const currentRiskVariables = self.props.payload.reports.riskVariables || [];
      const relatedRiskVariables =
        (self.props.selectedRelatedReport && self.props.selectedRelatedReport.reports.riskVariables)
        || [];

      const result = [];

      for (const variable of currentRiskVariables)
        result.push({
          current: variable,
          related: { ...variable, value: 0 }
        });

      for (const variable of relatedRiskVariables) {
        let maybeExisting = result.find(({ current }) => current.title === variable.title);

        if (maybeExisting) {
          maybeExisting.related = variable;
        } else {
          maybeExisting = {
            related: variable,
            current: { ...variable, value: 0 }
          };
          result.push(maybeExisting);
        }
      }

      return result;
    };

    return (
      <div className="page group-report page-last" style={{ marginTop: '100px' }}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5 pt-3">
            <h4 className="mb-3 text-uppercase">Mean Adversity Scores by Indicator</h4>
            <table className="table table-sm table-tm-dark-striped">
              <thead>
                <tr>
                  <th>Indicator</th>
                  <th width="10%">{payload.nationalAverageName}</th>
                  <th width="10%">
                    {payload.semester} {payload.year} Mean
                  </th>
                  {
                    (this.props.selectedRelatedReport) &&
                    <th width="10%">{this.props.selectedRelatedReport.semester} {this.props.selectedRelatedReport.year} Mean</th>
                  }
                  <th width="10%">Range</th>
                  <th>Interpretation</th>
                </tr>
              </thead>
              <tbody>
                {
                  getAggregatedDistressScores({ self: this }).map(({ current, related }, index) => {
                    return (
                      <tr key={index}>
                        <td className="font-weight-bold">{current.title}</td>
                        <td>{current.national}</td>
                        <td>{current.survey}</td>
                        {this.props.selectedRelatedReport && <td>{related.survey}</td>}
                        <td>{current.range}</td>
                        <td>{current.interpretation}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            <div className="pt-2">
              <div className="box-comment">
                <h3>What's this table telling me?</h3>
                <p>
                  {isAdult ? getContent("report_overall_page06_table_1_adult") : getContent("report_overall_page06_table_1_student")}
                </p>
                <h3>{isAdult ? getContent("report_overall_page03_risk-heading_adult") : getContent("report_overall_page03_risk-heading")}</h3>
                <p>
                  {isAdult ? getContent("report_overall_page06_table_2_adult") : getContent("report_overall_page06_table_2_student")}
                </p>
              </div>
            </div>

            <h4 className="mb-3 mt-5 text-uppercase">{payload.school} Participant Distribution on Adversity Indicators</h4>
            <div className="row">
              <div className="col-7">
                <table className="table table-sm table-tm-dark-striped">
                  <thead>
                    <tr>
                      <th>Indicator</th>
                      <th className="w-25 col-red">{payload.semester} {payload.year} Mean <br />May Need Assistance</th>
                      {
                        (this.props.selectedRelatedReport) &&
                        (
                          <th className="w-25 col-red">
                            {this.props.selectedRelatedReport.semester} {this.props.selectedRelatedReport.year} Mean
                            <br />
                            May Need Assistance
                          </th>
                        )
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      getAggregatedRiskVariables({ self: this }).map(({ current, related }, index) => {
                        return (
                          <tr key={index}>
                            <td className="font-weight-bold">{current.title}</td>
                            <td className="col-red score">
                              {current.value > 0 ?
                                isAdult ? <span>{Math.round(current.value)}%</span> : <a href={isUK ? tmEducatorsLinkUK : tmEducatorsLink}>{Math.round(current.value)}%</a>
                                : `${Math.round(current.value)}%`}
                            </td>
                            {
                              (this.props.selectedRelatedReport) &&
                              <td className="col-red">{Math.round(related.value) || 0}%</td>
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-5">
                <div className="box-comment">
                  <h3>What's this table telling me?</h3>
                  <p>This table presents the percentage of participants who scored in the May Need Assistance range across selected adversity indicators.</p>
                  <h3>{isAdult ? getContent("report_overall_page03_risk-heading_adult") : getContent("report_overall_page03_risk-heading")}</h3>
                  <p>
                    {isAdult ? getContent("report_overall_page06_table_3_adult") : getContent("report_overall_page06_table_3_student")}
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedRelatedReport: state.overallSurveyReport.selectedRelatedReport
  };
};

export default connect(
  mapStateToProps
)(withRouter(DistressScores));

