import React from 'react';
import { getContent } from '../../../../../utilities/getContent/getContent';
import { Label } from 'reactstrap';
import * as actions from '../../../../../store/actions';
import { connect } from 'react-redux';
import Select from 'react-select';

const CategorySelector = props => {

  const options = [
    { label: getContent('admin_label-grK-3'), value: 'k3' },
    { label: getContent('admin_label-gr3-5'), value: 'elementary' },
    { label: getContent('admin_label-upper-grades'), value: 'uppergrades' },
    { label: getContent('admin_label-adult'), value: 'adult' },
  ];

  return (
    <div className="button-container-indicator-select my-3">
      <div className="dropdown-container">
        <Label>Age Group:</Label>
        <Select
          id="locator_select-date-period"
          options={options}
          className="tm-dropdown"
          onChange={ o => props.setAppliedTo( o.value )}
          placeholder="Please select..."
          value={options.find(o => o.value === props.appliedTo)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    appliedTo: state.surveyCrud.createSurveyObj.appliedTo,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCheckedIndicatorInForm: indicatorId =>
      dispatch(actions.toggleCheckedIndicatorInForm(indicatorId)),
    setAppliedTo: value => dispatch(actions.setAppliedTo(value)),
    toggleCategoryIndicators: category =>
      dispatch(actions.toggleCategoryIndicators(category)),
    setProfession: profession => dispatch(actions.setProfession(profession)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelector);
