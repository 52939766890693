import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateSurveyStepGroup from './CreateSurveyStepGroup/CreateSurveyStepGroup';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import Spinner from '../../../../components/Spinner/Spinner';
import * as actions from '../../../../store/actions';
import { useParams } from 'react-router';

const CreateSurvey = React.memo(() => {
  const dispatch = useDispatch();
  const { surveyId } = useParams();
  const {
    isLoading,
    activeOrg,
    existingSurveyDetails,
    indicatorsLoaded,
  } = useSelector(state => ({
    isLoading: state.surveyCrud.isLoading,
    // parentOrg: state.navigation.org?.parentOrg,
    activeOrg: state.navigation.org,
    existingSurveyDetails: state.surveyCrud.surveyDetails,
    indicatorsLoaded:
      state.surveyCrud.indicators && state.surveyCrud.indicators.length > 0,
  }));
  const schoolOrgId =
    activeOrg?.classification === 'school' ? activeOrg.orgId : null;

  useEffect(() => {
    // first load indicators
    if (!indicatorsLoaded) dispatch(actions.listIndicators(surveyId === undefined));
    else {
      dispatch(actions.clearCreateSurveyState());
      // editing survey
      if (surveyId !== undefined) {
        if (indicatorsLoaded && existingSurveyDetails.surveyId === surveyId) {
          // dispatch(actions.listUsers( existingSurveyDetails.orgId , true))
          dispatch(actions.createSurveyFromExisting());
        }
        // new survey
      } else {
        if (schoolOrgId) {
          dispatch(actions.listUsers( schoolOrgId , true))
          dispatch(actions.setSurveyOrgId(schoolOrgId));
        }
      }
    }
  }, [activeOrg, surveyId, existingSurveyDetails, indicatorsLoaded]);

  const actionLabel = (surveyId ? 'Edit' : 'Create') + " Assessment";
  const introText = surveyId
    ? 'Complete this form to edit the previously selected assessment. Once Assessment is In Progress, sections 1 and 2 cannot be edited.'
    : 'Complete this form to create a new assessment. Participants can be added after the assessment is created.';
  return (
    <div className="create-survey-container">
      <div className="sub-header__create_assessment">
        {actionLabel}
      </div>
      {schoolOrgId ? (
        <>
          <p>{introText}</p>
          {isLoading || !indicatorsLoaded ? (
            <Backdrop>
              <Spinner />
            </Backdrop>
          ) : (
            <CreateSurveyStepGroup
              createSurvey={() => dispatch(actions.createSurvey())}
            />
          )}  
        </>
      ) : (
        <div className="error-message">
          <p>Error: Please select District and School to create a survey.</p>
        </div>
      )}
    </div>
  );
});
CreateSurvey.displayName = 'CreateSurvey';

export default CreateSurvey;
