export default {
  "trauma": {
    label: 'indicator_trauma',
    source: 'https://kidshealth.com',
    tips: [
      'report_suggestions-trauma-1',
      'report_suggestions-trauma-2',
      'report_suggestions-trauma-3',
      'report_suggestions-trauma-4',
    ],
  },
  'drug_alcohol': {
    label: 'indicator_drug-alcohol',
    tips: [
      'report_suggestions-drug-alcohol-1',
      'report_suggestions-drug-alcohol-2',
      'report_suggestions-drug-alcohol-3',
      'report_suggestions-drug-alcohol-4',
      'report_suggestions-drug-alcohol-5',
    ],
  },
  'standards': {
    label: 'indicator_personal-standards',
    tips: [
      'report_suggestions-personal-standards-1',
      'report_suggestions-personal-standards-2',
      'report_suggestions-personal-standards-3',
    ],
  },
  "leadership": {
    label: 'indicator_leadership',
    tips: [
      'report_suggestions-leadership-1',
      'report_suggestions-leadership-2',
      'report_suggestions-leadership-3',
    ],
  },
  "resiliency": {
    label: 'indicator_resiliency',
    tips: [
      'report_suggestions-resiliency-1',
      'report_suggestions-resiliency-2',
      'report_suggestions-resiliency-3',
    ],
  },
  "depression": {
    label: 'indicator_depression',
    source: 'https://helpguide.org',
    tips: [
      'report_suggestions-depression-1',
      'report_suggestions-depression-2',
      'report_suggestions-depression-3',
      'report_suggestions-depression-4',
      'report_suggestions-depression-5',
    ],
  },
  "hope": {
    label: 'indicator_hope',
    tips: [
      'report_suggestions-hope-1',
      'report_suggestions-hope-2',
      'report_suggestions-hope-3',
      'report_suggestions-hope-4',
    ],
  },
  "anxiety": {
    label: 'indicator_anxiety',
    tips: [
      'report_suggestions-anxiety-1',
      'report_suggestions-anxiety-2',
      'report_suggestions-anxiety-3',
      'report_suggestions-anxiety-4',
      'report_suggestions-anxiety-5',
    ],
  },
  'global_satisfaction': {
    label: 'indicator_global-satisfaction',
    tips: [
      'report_suggestions-global-satisfaction-1',
      'report_suggestions-global-satisfaction-2',
      'report_suggestions-global-satisfaction-3',
    ],
  },
  "ostracism": {
    label: 'indicator_ostracism',
    tips: [
      'report_suggestions-ostracism-1',
      'report_suggestions-ostracism-2',
      'report_suggestions-ostracism-3',
    ]
  },
  'victimization': {
    label: 'indicator_getting-bullied',
    tips: [
      'report_suggestions-getting-bullied-1',
      'report_suggestions-getting-bullied-2',
      'report_suggestions-getting-bullied-3',
      'report_suggestions-getting-bullied-4',
      'report_suggestions-getting-bullied-5',
      'report_suggestions-getting-bullied-6',
      'report_suggestions-getting-bullied-7',
      'report_suggestions-getting-bullied-8',
      'report_suggestions-getting-bullied-9',
      'report_suggestions-getting-bullied-10',
      'report_suggestions-getting-bullied-11',
      'report_suggestions-getting-bullied-12',
      'report_suggestions-getting-bullied-13',
      'report_suggestions-getting-bullied-14',
    ]
  },
  'school_violence': {
    label: 'indicator_school-violence',
    tips: [
      'report_suggestions-school-violence-1',
      'report_suggestions-school-violence-2',
      'report_suggestions-school-violence-3',
      'report_suggestions-school-violence-4',
    ]
  },
  "grit": {
    label: 'indicator_grit',
    tips: [
      'report_suggestions-grit-1',
      'report_suggestions-grit-2',
      'report_suggestions-grit-3',
    ]
  },
  'bullying': {
    label: 'indicator_bullying-others',
    tips: [
      'report_suggestions-bullying-others-1',
      'report_suggestions-bullying-others-2',
      'report_suggestions-bullying-others-3',
      'report_suggestions-bullying-others-4',
      'report_suggestions-bullying-others-5',
      'report_suggestions-bullying-others-6',
    ]
  },
  'positive_experiences': {
    label: 'indicator_positive-school-experiences',
    tips: [
      'report_suggestions-positive-school-experiences-1',
      'report_suggestions-positive-school-experiences-2',
    ]
  },
  'self_criticism': {
    label: 'indicator_self-criticism',
    intro: 'report_suggestions-self-criticism-intro',
    tips: [
      'report_suggestions-self-criticism-1',
      'report_suggestions-self-criticism-2',
      'report_suggestions-self-criticism-3',
    ]
  },
};
